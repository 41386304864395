/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';

import Navbar from "./navbar";
import Footer from "./footer";

import { ThemeProvider } from "styled-components";
import GlobalStyle, { avicennaTheme } from "../theme/GlobalStyle";
import Text from "./text";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const Spacer = styled.div`
    margin-bottom: -80px;
  `;

  const StyledLink = styled(GatsbyLink)`
    color: #065C5D;
  `;

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={avicennaTheme}>
        <Navbar siteTitle={data.site.siteMetadata.title} />
        <main>
          {children}
        </main>
        <Spacer />
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="gatsby-gdpr-google-analytics"
          style={{ background: "#C2D100", padding: "10px" }}
          buttonStyle={{ 
            color: "#065C5D", 
            fontSize: "18px", 
            background: "white", 
            padding: "10px 18px", 
            marginRight: "32px",
            borderRadius: "8px" 
          }}
        >
          <Text sx={{ color: "primary", fontFamily: "bold"}}>
            Our site uses cookies. To found out more please visit our <StyledLink to="/cookie-policy">Cookie Policy page</StyledLink>.
          </Text>
        </CookieConsent>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
