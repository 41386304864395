import React from "react";
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled, { AnyStyledComponent } from "styled-components";
import { Box, Link } from "rebass/styled-components";
import { space, layout, LayoutProps } from "styled-system";
import NHSLogo from "../images/nhs-logo.svg";
import DownIcon from "./icons/downIcon";
import Text from "./text";

const StyledFooter = styled.footer<LayoutProps>`
  background-color: ${(props) => props.theme.colors.white};
  margin: 0px auto 16px;
  ${layout};
  z-index: 10;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;

const StyledNHSLogo = styled(NHSLogo as AnyStyledComponent)`
  ${space}
  ${layout}
  right: 0;
  display: flex;
  justify-content: center;
  padding-top: 16px;
`;

const SocialImg = styled(Img)`
  margin-left: ${(props) => props.theme.space[2]}px;
  margin-top: ${(props) => props.theme.space[2]}px;
`;

type FooterProps = {
  linkId?: string;
  isNotBottomFooter?: boolean;
};

const Footer = ({ linkId, isNotBottomFooter = false }: FooterProps) => {
  const data = useStaticQuery(graphql`
    query {
      fbImage: file(relativePath: { eq: "socials-facebook.png" }) {
        childImageSharp {
          fixed(width: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      googleImage: file(relativePath: { eq: "socials-google.png" }) {
        childImageSharp {
          fixed(width: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      twitterImage: file(relativePath: { eq: "socials-twitter.png" }) {
        childImageSharp {
          fixed(width: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      linkedInImage: file(relativePath: { eq: "socials-linkedin.png" }) {
        childImageSharp {
          fixed(width: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <StyledFooter maxWidth="1300px">
      <Box m={[2, 3, 4]} width={["1", "auto", "auto"]}>
        {!isNotBottomFooter && (
          <>
            <GatsbyLink to="/terms">
              <Link variant="bigLink" color="text" as="span" mb={4}>
                data &amp; privacy
              </Link>
            </GatsbyLink>
            <Text fontSize={0}>
              Google Play and the Google Play logo are trademarks of Google LLC.
              <br />
              Apple and the Apple logo are trademarks of Apple Inc. App Store is
              a service mark of Apple Inc.
            </Text>
          </>
        )}
        <Box marginTop="28px" mr={[2, 3, "40px"]} display={["inherit"]}>
          <a
            href="https://www.facebook.com/avicennapharmacyuk/"
            aria-label="Avicenna Facebook page"
          >
            <SocialImg fixed={data.fbImage.childImageSharp.fixed} />
          </a>
          <a
            href="https://www.google.com/search?q=avicenna-buying-group"
            aria-label="Avicenna Google page"
          >
            <SocialImg fixed={data.googleImage.childImageSharp.fixed} />
          </a>
          <a
            href="https://twitter.com/Avicennaplc"
            aria-label="Avicenna Twitter page"
          >
            <SocialImg fixed={data.twitterImage.childImageSharp.fixed} />
          </a>
          <a
            href="https://www.linkedin.com/company/avicenna-plc/"
            aria-label="Avicenna LinkedIn page"
          >
            <SocialImg fixed={data.linkedInImage.childImageSharp.fixed} />
          </a>
        </Box>
      </Box>
      {linkId && (
        <Box
          marginTop="30px"
          mr={[2, 3, "40px"]}
          display={["inherit"]}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href={linkId}>
            <DownIcon />
          </a>
        </Box>
      )}
      <Box width={["1", "auto", "auto"]}>
        <StyledNHSLogo m={[2, 3, 4]} width={["150px", "210px", "200px"]} />
      </Box>
    </StyledFooter>
  );
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
