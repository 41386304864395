import React from "react";

const DownIcon = () => (
  <svg width="49px" height="24px" viewBox="0 0 49 24" version="1.1" >
    <title>down-arrow</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="NEW" transform="translate(-659.000000, -834.000000)">
            <rect fill="#FFFFFF" x="0" y="0" width="1366" height="896"></rect>
            <g id="down-arrow" transform="translate(659.000000, 833.000000)">
                <g>
                    <polygon id="Path" fill="#065C5D" fill-rule="nonzero" points="48.1585258 1.50366069 39.6121727 1.49950696 24.499 16.179 9.38652908 1.49926115 0.842922689 1.50548373 24.500017 24.4853093"></polygon>
                </g>
            </g>
        </g>
    </g>
  </svg>
)

export default DownIcon;