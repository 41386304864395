import React, { useState } from 'react';
import styled, { AnyStyledComponent } from 'styled-components';
import { space, layout, LayoutProps } from 'styled-system';
import { Link as GatsbyLink } from 'gatsby';
import { Box, Flex, Link } from 'rebass/styled-components';

import Logo from '../images/avicenna-logo.svg';
import MenuIcon from '../images/menu-icon.svg';
import Text from '../components/text';

// styled-system/layout is providing the ability to pass a height prop
const StyledHeader = styled.header<LayoutProps>`
  background-color: ${(props) => props.theme.colors.white};
  ${layout};

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  z-index: 10;
`;

type Link = {
  href: string;
  title: string;
  external?: boolean;
  hide?: boolean;
};

const links: Array<Link> = [
  { href: '/', title: 'home' },
  {
    href: 'https://www.avicenna.org/about-us/our-vision/',
    title: 'about',
    external: true,
  },
  { href: '/pharmacies', title: 'pharmacies' },
  { href: '/services', title: 'book a service', hide: process.env.GATSBY_FEATURE_SERVICE_BOOKING !== 'true' },
  {
    href: 'https://app.minutemeds.co.uk/avicenna',
    title: 'online doctor',
    external: true,
  },
  { href: '/help', title: 'help' },
  {
    href: `${process.env.GATSBY_WEB_APP_URL}/#/login`,
    title: 'log in',
    external: true,
  },
];

// styled-system/space is providing the ability to pass a m prop
// styled-system/layout is providing the ability to pass a width prop
const StyledLogo = styled(Logo as AnyStyledComponent)`
  ${space}
  ${layout}
`;

const StyledMenuIcon = styled(MenuIcon as AnyStyledComponent)`
  ${space}
  ${layout}
`;

const StyledText = styled(Text)`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: -4px;
`;

const StyledLink = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fonts.bold};
`;

const getLink = (link: Link) => {
  if (link.external) {
    return (
      <a href={link.href}>
        <Link variant="bigLink" color="text" mx={3} as="span">
          <StyledLink>{link.title}</StyledLink>
        </Link>
      </a>
    );
  }

  return (
    <GatsbyLink to={link.href}>
      <Link variant="bigLink" color="text" mx={3} as="span">
        <StyledLink>{link.title}</StyledLink>
      </Link>
    </GatsbyLink>
  );
};

const Header = () => (
  <>
    <Box
      sx={{
        display: ['none', 'none', 'inherit'],
        position: 'sticky',
        top: 0,
        zIndex: 10,
      }}
    >
      <StandardHeader />
    </Box>
    <Box sx={{ display: ['inherit', 'inherit', 'none'] }}>
      <CollapsedHeader />
    </Box>
  </>
);

const StyledLogin = styled.a`
  display: flex;
  align-items: center;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  border-radius: 4px;
  padding: 0px 16px;
`;

const StandardHeader = () => (
  <Flex bg="white" margin="0 auto" maxWidth="1300px">
    <Box ml={[0, 2, 2]} mt={1} mb={3} sx={{ display: 'flex', alignItems: 'flex-end' }}>
      {links
        .filter((l) => !l.hide)
        .map((l, i) => (
          <React.Fragment key={i}>
            {getLink(l)}
            {i !== links.length - 1 && <StyledText>|</StyledText>}
          </React.Fragment>
        ))}
    </Box>
    <Box width="50%" height="100px" flex={1} marginRight="32px" display="flex" sx={{ justifyContent: 'flex-end' }}>
      <a href="/">
        <StyledLogo p={[1, 3, 3]} ml={[0, 4, 4]} mt={2} width={['0', '325px', '325px']} />
      </a>
    </Box>
  </Flex>
);

const CollapsedHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <StyledHeader height="75px">
        <Box display="inline-block" my="auto">
          <StyledLogo p={1} width="200px" px={3} />
        </Box>
        <Box display="inline-block" my="auto" px={3} onClick={() => setMenuOpen(!menuOpen)}>
          <StyledMenuIcon height="20px" css={menuOpen ? { transform: 'rotate(90deg)' } : {}} />
        </Box>
      </StyledHeader>
      {menuOpen &&
        links.map((l, i) => (
          <Box height="40px" my="auto" key={i}>
            {getLink(l)}
          </Box>
        ))}
    </>
  );
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
